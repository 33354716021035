.ripple{
  padding: 22px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::before, &::after{
    position: absolute;
    content: '';
    border-radius: 50%;
  }

  &::before{
    background-color: var(--blue-1);
    height: 100%;
    width: 100% ;
    z-index: 1;
    top: 0;
    left: 0;
  }

  &::after{
    background-color: var(--blue-2);
    opacity: 0.8;
    height: calc(100% - 22px);
    width: calc(100% - 22px);
    z-index: 2;
    top: 11px;
    left: 11px;
  }
}

.badge{
  position: relative;
  z-index: 3;
}

.icon{
  color: var(--ant-primary-color);
  position: relative;
  z-index: 3;
}