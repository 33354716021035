@import '../../../styles/variables';
@import '../../../styles/responsive';

.container {
  background-color: $blue-darkest;

  .containerText {
    padding: 24px 0;
    color: #acaeb7;
    border-top: 1px solid #262b4b;

    @include responsive(mobile-large) {
      margin: 0 24px;
    }

    font-family: Inter,sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }
}
