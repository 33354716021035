.inner{
  display: block;
  height: 1.5em;
  width: 1.5em;
  border-radius: 0.75em;
  padding: 0.2137em;
  font-size: 1em;
}

.icon{
  display: block; 
  padding: 0.1em;
  border-radius: 50%;

  svg{
    display: block;
    line-height: 1em;
    font-size: 1em;
  }
}

.plus{
  background: linear-gradient(90deg, #9c55d3 0%, #f4e7ff 98.55%);
  box-shadow: -1px -1px 3px 0 rgb(193 195 196 / 90%) inset, 1px 1px 2px 0 rgb(255 255 255 / 90%) inset, -1px 1px 2px 0 rgb(193 195 196 / 20%) inset, 1px -1px 2px 0 rgb(193 195 196 / 20%) inset;

  .inner{
    background: linear-gradient(270deg, #9c55d3 0%, #f4e7ff 98.55%);
  }
}

.pro{
  background: linear-gradient(90deg, #2f73db 0%, #5fcfff 98.55%);

  .inner{
    background: linear-gradient(270deg, #2f73db 0%, #5fcfff 98.55%);
  }
}

.essentials{
  background: linear-gradient(90deg, #2a997e 0%, #17ffc8 98.55%);
  box-shadow: -1px -1px 3px 0 rgb(193 195 196 / 90%) inset, 1px 1px 2px 0 rgb(255 255 255 / 90%) inset, -1px 1px 2px 0 rgb(193 195 196 / 20%) inset, 1px -1px 2px 0 rgb(193 195 196 / 20%) inset, 1px 1px 2px 0 rgb(193 195 196 / 50%), -1px -1px 2px 0 rgb(255 255 255 / 30%);

  .inner{
    background: linear-gradient(270deg, #2a997e 0%, #17ffc8 98.55%);
  }
}