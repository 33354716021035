/* stylelint-disable selector-class-pattern */
@import '../styles/variables';
@import '../styles/responsive';

.container {
  background-color: $text-blue-darker;

  .container__menu {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 64px 24px;
    padding: 32px 0;
    border-bottom: 1px solid $text-blue-dark-light;

    @include responsive(mobile-large) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include responsive(pc-small) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  a{
    color: inherit;
  }
}
