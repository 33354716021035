.headerWrapper {
  padding: 0 24px;
  min-height: var(--tbd-header-height);
  display: flex;
  align-items: center;
  background-color: white;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  transition: transform 0.3s;
  box-shadow: 0 1px 0 var(--border-color);

}

.wrap{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.selectApp{
  flex: 0 0 auto;
}

.menu{
  width: 100%;
  border: none;
  background: transparent;
}

.headerScrollDown {
  transform: translateY(-101%);
}

.loginButtonDesktop {
  text-transform: uppercase;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.loginButtonDrawer {
  text-transform: uppercase;
  color: var(--gray-1);
  border: 1px solid var(--gray-1);
}

.headerScrollUp {
  transform: translateY(0);
}

.headerTransparent {
  background-color: transparent;
  box-shadow: unset;
}

.leftMenuDesktop{
  background-color: transparent;
  border: none;
  width: 100%;

  li{
    list-style: none;
    border: none;
  }
}

.rightMenuDesktop {
  display: flex;
  gap: 8px;
}

.other{
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  padding: 16px 24px;
}