// TODO unify with variables in theboat_db, theboat_landing, mdc_landing

$primary-dark: #09008a;
$primary: #1e00d5;
$danger: #ff4136;
$blue-darkest: #000420;
$blue-light: #d1e8ff;
$blue-lighter: #f0fffe;
$blue-lightest: #ebebed;
$blue-lightest-more: #dfe4ea;
$purple: #9879fc;
$purple-light: #c3acff;
$purple-lightest: #f0ebff;
$text-blue-darker: #050b30;
$text-blue-dark: #18214d;
$text-blue-dark-light: #262b4b;
$text-blue-dark-lighter: #3e4462;
$text-blue-dark-lightest: #53576f;
$try-gold-button: linear-gradient(82.89deg, #33def2 5.55%, #1e7ad5 50%, #1e00d5 94.45%);
$blue-6: #1e00d5;
$blue-1: #f0ebff;
$grey-10: #050b30;
$grey-9: #262b4b;
$grey-8: #53576f;
$grey-7: #737585;
$grey-6: #acaeb7;
$grey-5: #d0d1d6;
$grey-2: #f7f7f8;
$grey-1: #fff;
