@import '../../../styles/responsive';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  border-bottom: 1px solid #262b4b;
  padding: 24px;

  @include responsive(mobile-large) {
    padding: 24px 0;
  }

  @include responsive(tablet) {
    flex-direction: row;
  }

  .containerDownload {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 14px;

    @include responsive(mobile-large) {
      grid-template-columns: auto auto;
    }
  }

  .containerMenu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    margin-bottom: 0;
    padding: 0;

    .containerItem {
      color: white;

      @include responsive(tablet) {
        margin-top: 0;
      }

      .containerItemLink {
        width: 32px;
        height: 32px;
        margin: 9px;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
