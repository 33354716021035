@import '../../../styles/variables';
@import '../../../styles/responsive';

.container {
  background-color: $blue-darkest;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  grid-gap: 24px;

  @include responsive(xs) {
    padding: 40px 24px 32px;
    flex-direction: column;

  }

  @include responsive(sm) {
    flex-direction: column;
  }

  @include responsive(md) {
    flex-direction: row;

  }

  @include responsive(lg) {
    flex-direction: row;

  }

  .containerLogo {
    @include responsive(mobile-large) {
      grid-column: 1 / span 2;
    }

    @include responsive(pc-small) {
      grid-column: 1;
    }
  }

  p {
    margin: 0;
    color: #acaeb7;
    font-family: Inter,sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
  }
}